<template>
	<div class="part-catalogue">
		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<v-spacer/>

			<v-progress-circular
				:size="100"
				color="primary"
				indeterminate/>

			<v-spacer/>
		</div>

		<div class="catalogue-container">
			<div class="top-buttons">
				<v-btn 
					style="margin-right: 10px;"
					width="150"
					:disabled="previousIDs.length == 0 || this.previousIDs.slice(-1) == -1"
					small
					color="primary"
					@click="backTier(true)">
					<v-icon>mdi-skip-backward</v-icon> Reset
				</v-btn>

				<v-btn 
					width="150"
					:disabled="previousIDs.length == 0 || this.previousIDs.slice(-1) == -1"
					small
					color="success"
					@click="backTier(false)">
					<v-icon>mdi-step-backward</v-icon> Up One Level
				</v-btn>
			</div>
		
			<div class="views-container">
				<div class="view"
					:style="`background-image: url('${imageAddress(tier.image)}'); background-size: contain;`"
					v-for="tier, index in tiers"
					:key="index"
					@click="selectTier(tier.id)">
					
					<v-spacer />
					
					<div class="view-info">
						<h5>{{ tier.name}}:</h5>
						<div style="overflow-x: hidden; overflow-y: auto; font-size: 1.2vh;">
							{{ tier.description }}
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'PartsCatalogue',
	components: {
	},
	props: {
	},
	data () {
		return {
			loading: false,
			previousIDs: [],
			parentID: -1,
			results: [],
			tiers: [],
			parts: [],
			back: false,
		}
	},
	methods: {
		getTier() {
			this.results = []
			this.loading = true;

			var formData = new FormData();

			formData.append("parentid", this.parentID)

			axios.post(`${this.$config.api}/api.php?cmd=getTier`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					this.loading = false;
					console.log(response.data)
					return
				}

				if (!this.back) {
					this.previousIDs.push(this.parentID)
				} else {
					this.back = false
				}

				this.clear()
				this.results = response.data.results
				this.sortResults()
				console.log(response.data)
				this.loading = false
			})
			.catch(error => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log("Server returned status code:")
					console.log(error.response.data)
					console.log(error.response.status)
					console.log(error.response.headers)
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log("Server didn't respond:")
					console.log(error.request)
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log("Unknown Error:")
					console.log('Error', error.message)
				}
			})
		},
		sortResults() {
			for (var result of this.results) {
				if (result.toplevel) {
					this.tiers.push(result)
					continue
				}

				if (result.tierid != -1) {
					this.tiers.push(result)
					continue
				}

				if (result.partid != -1) {
					this.parts.push(result)
					continue
				}

			}
		},
		selectTier(parentID) {
			console.log(`Select Parent Tier: ${parentID}`)
			//this.previousID = this.parentID
			this.parentID = parentID
			this.getTier()
		},
		backTier(toStart) {
			if (!toStart) {
				this.previousIDs.pop()
				console.log(`Back To Tier: ${this.previousIDs.slice(-1)}`)
				this.parentID = this.previousIDs.slice(-1)
				this.back = true
			} else {
				console.log(`Back To Start`)
				this.previousIDs = [-1]
				this.parentID = -1
			}

			this.getTier()
		},
		imageAddress(image) {
			if (image.length > 0){
				return `${this.$config.images}${image}`
			}

			return `${this.$config.images}No_Image_Available.jpg`
		},
		clear() {
			this.results = []
			this.tiers = []
			this.parts = []
		},
	},
	computed: {
	},
	mounted() {
		this.getTier()
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.part-catalogue {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.catalogue-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		padding: 5px;
		border: 1px solid silver;
		background-color: white;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.loading {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 33px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 5;
	}

	.views-container {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		justify-content: center;
		gap: 10px;
  		flex-grow: 1;
		height: 100%;
		width: 100%;
		padding: 10px;
		overflow-x: hidden;
		overflow-y: scroll;
		border: 1px solid silver;
		border-radius: 5px;
	}

	.view {
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 15.5vw;
		height: 18vw;
		border: 1px solid gray;
		border-radius: 5px;
		background-color: rgba(0,0,0,0.1);
		object-fit: contain;
	}

	.view:hover {
		cursor: pointer;
		background-color: rgba(158, 184, 255, 0.4);
	}

	.top-buttons {
		display: flex;
		flex-direction: row;
		flex-shrink: 1;
		align-items: center;
		width: 100%;
		margin-bottom: 5px;
	}

	.image-preview {
		background-color: white;
		max-width: 95%;
		max-height: 50%;
		object-fit: contain;
	}

	.view-info {
		display: flex;
		flex-direction: column;
		align-items: start;
		width: 100%;
		height: 30%;
		padding: 5px;
		border-top: 1px solid gray;
		border-radius: 5px;
		color: white;
		background-color: rgba(0,0,0,0.5);
	}
</style>