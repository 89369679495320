export default {
	namespaced: true,
  	state: {
		showPricing: true,

		// Discount information. 
		discount: {
			display: "0",			// String representation of discount percent (ie. "30")
			dealer: 0.0,			// Float value ie. (0.3)
			decimal: 0.0,			// Float value of 1 - dealer ie. (0.7)
		},

		currentOrder: {
			parts: [],					// Array of parts objects. Format: {part: "Part Number", quantity: 1, description: "Part Description 1", retail: 1.99}
			po: "",                 	// Purchase order. Can have numbers and letters
			dealerName: "",				// Dealer name that initiated the delivery
			orderNote: "",				// Order related notes

			// Contact information.
			contact: {
				name: "",				// Receiver's name
				email: "",				// Receiver's contact email
				phone: "",				// Reciever's contact phone number
				fedTaxID: "",			// Receiver's Federal Tax ID. Only used for Canada to US Dropships and is required to clear customs.
			},
			// Courier information.
			courier: {
				name: "",				// Courier's business name
				account: "",			// Dealer's courier account number for collect shipments
			},
			// Shipment urgency
			urgency: {
				economy: true,			// Send order ground cheapest way, no urgency
				express: false,			// Send order ground fastest way within reason for cost (ie. 3 days instead of 7)
				overnight: false,		// Send order over night air, extremely expensive and emergencies only
			},
			// Pickup information.
			pickup: {
				customer: false,		// Is this a customer pickup? Used to inform not to send priced paperwork with order
				name: "",				// Name of person authorized to release order to
				phone: "",				// Phone number of person picking up order in case we need to contact them
				time: ""				// Estimated time of pickup, request only as we will have to authorize the pickup
			},
			// Dealer address either used to ship to or to identify the dealer location that initiated a dropship
			address: {
				address1: "",			// Standard address line
				address2: "",			// Special address information
				address3: "",			// Extra address information
				city: "",				// City of delivery
				state: "",				// State/Province of delivery
				country: "",			// Country of delivery
				postal: "",				// Zip/Postal code of delivery
			},
			// Dropship Information
			dropship: false,			// Is this order a dropship?
			// Dropship Address
			dropshipAddress: {
				address1: "",			// Standard address line
				address2: "",			// Special address information
				address3: "",			// Extra address information
				city: "",				// City of delivery
				state: "",				// State/Province of delivery
				country: "",			// Country of delivery
				postal: "",				// Zip/Postal code of delivery
			},
		},
	},
	mutations: {
		SET_URGENCY: (state, urgency) => {
			state.currentOrder.urgency = urgency
		},
		ADD_PART: (state, part) => {
			var updated = false

			if (state.currentOrder.parts.length > 0) {
				for (var item of state.currentOrder.parts) {
					if (item.part == part.part && updated == false) {
						item.quantity = parseInt(item.quantity) + parseInt(part.quantity)
						updated = true
					} 
				}

				if (updated == false) {
					state.currentOrder.parts.push(part)
				}
			} else {
				state.currentOrder.parts.push(part)
			}
		},
		EDIT_PART: (state, index, part) => {
			state.currentOrder.parts[index] = part
		},
		REMOVE_PART: (state, index) => {
			state.currentOrder.parts.splice(index, 1)
		},
		CLEAR_PARTS: (state) => {
			state.currentOrder.parts = []
		},
		CLEAR_CART: (state) => {
			state.currentOrder.parts = []
		},
		SET_CART: (state, cart) => {
			// Verify minimum cart definitions
			if (cart.contact 		 == null || typeof cart.contact 		!== 'object'	||
				cart.courier 		 == null || typeof cart.courier 		!== 'object'	||
				cart.pickup 		 == null || typeof cart.pickup  		!== 'object'	||
				cart.address 		 == null || typeof cart.address 		!== 'object'	||
				cart.urgency 		 == null || typeof cart.urgency 		!== 'object'	||
				cart.dropshipAddress == null || typeof cart.dropshipAddress !== 'object') {
					console.log("Cart not updated! Definitons issue in vuex cart.")
					return
			}

			// Set the current order supplied cart object
			state.currentOrder = cart
		},
		SET_SHOW_PRICING: (state, show) => {
			state.showPricing = show
		},
		SET_DISCOUNT: (state, discount) => {
			// Take single discount number and convert it into the 3 values used by discount.
			var newDiscount = {
				display: "0",
				dealer: 0.0,
				decimal: 1.0,
			}

			// Cart discount verifications and conversions
			if (discount != null) {
				// If there is a non object value for discount then set it to the proper object format
				if (typeof discount != 'object') {
					// If the number is greater than 1 it is assumed to be a full percent like 35 so set object accordingly
					if (discount > 1) {
						newDiscount.display = String(discount)
						newDiscount.dealer = parseFloat(discount) / 100
						newDiscount.decimal = 1 - newDiscount.dealer
					// If the number was less than 1 then it is assumed to be the dealer float version so set proper object format
					} else {
						newDiscount.display = String(parseFloat(discount) * 100)
						newDiscount.dealer = parseFloat(discount)
						newDiscount.decimal = 1 - newDiscount.dealer
					}

				// Make sure cart's discount object conforms to definition and if not, set default discount object
				} else {
					if (discount.display 	== null 	|| discount.display		== "" ||
						discount.dealer 	== null 	|| discount.dealer 		== "" ||
						discount.decimal 	== null		|| discount.decimal 	== "" ) {
						discount = discount
					}
				}
			}

			state.discount = newDiscount
		},
	},
	actions: {
		SET_URGENCY(context, urgency) {
			context.commit('SET_URGENCY', urgency)
		},
		ADD_PART(context, part) {
			context.commit('ADD_PART', part)
		},
		EDIT_PART(context, index, part) {
			context.commit('EDIT_PART', index, part)
		},
		REMOVE_PART(context, index) {
			context.commit('REMOVE_PART', index)
		},
		CLEAR_PARTS(context) {
			context.commit('CLEAR_PARTS')
		},
		CLEAR_CART(context) {
			context.commit('CLEAR_CART')
		},
		SET_CART(context, cart) {
			context.commit('SET_CART', cart)
		},
		SET_SHOW_PRICING(context, show) {
			context.commit('SET_SHOW_PRICING', show)
		},
		SET_DISCOUNT(context, discount) {
			context.commit('SET_DISCOUNT', discount)
		},
	},
	getters: {
		GET_PARTS: state => {
			return state.currentOrder.parts
		},
		GET_CART: state => {
			return state.currentOrder
		},
		GET_SHOW_PRICING: state => {
			return state.showPricing
		},
		GET_DISCOUNT: state => {
			return state.discount
		},
	}
}
