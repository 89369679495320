<template>
	<div class="cart">
		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<v-spacer/>

			<v-progress-circular
				:size="100"
				color="primary"
				indeterminate/>

			<v-spacer/>
		</div>

		<!-- Order Submit Banner Component -->
		<div class="loading"
			v-if="showComplete">
			<v-spacer />

			<div class="order-complete">
				<div :style="completeBanner">
					<span v-html="completeTitle"></span>
				</div>

				<div class="order-message">
					<span v-html="completeMessage" />
				</div>
			</div>

			<v-spacer />
		</div>

		<!-- Add Part Component -->
		<div class="loading"
			v-if="showAddPart">
			<v-spacer />

			<div class="add-part">
				<div class="add-part-banner">
					<h3>Add Part:</h3>
					<v-spacer />
					<v-btn
						icon
						color="error"
						@click="showAddPart = false">
						<v-icon
							color="red"
							large
							light>
							mdi-close-circle
						</v-icon>
					</v-btn>
				</div>

				<div class="add-part-container">
					<div class="search-bar">
						<div style="width: 30vw; margin-right: 10px;">
							<v-text-field
								v-model="searchNumber"
								label="Part Number"
								hide-details="auto"
								outlined
								dense
								@keyup.enter.native="searchParts()"/>
						</div>

						<v-btn
							color="primary"
							@click="searchParts()">
							Search
						</v-btn>

						<span style="color: red; margin-left: 10px;"
							v-if="searchMessage.length > 0">
							*{{this.searchMessage}}
						</span>
					</div>

					<div class="search-results"
						v-if="searchResults.length > 0">
						<table>
							<thead class="fix-table-head">
								<td style="width: 20%;">Part Number</td>
								<td style="width: 50%;">Description</td>
								<td style="width: 10%;">Dealer</td>
								<td style="width: 10%;">Retail</td>
								<td style="width: 10%;"></td>
							</thead>

							<tr
								v-for="part, index of searchResults"
								:key="index">
								<td>{{part.part}}</td>
								<td>{{part.description}}</td>
								<td v-if="part.dealer != null" style="text-align: right;">${{parseFloat(part.dealer).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
								<td v-else style="text-align: center; background-color: lightgoldenrodyellow;">Contact</td>
								<td v-if="part.retail != null" style="text-align: right;">${{parseFloat(part.retail).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
								<td v-else style="text-align: center; background-color: lightgoldenrodyellow;">Contact</td>
								<td> <!-- Add To Cart Column -->
									<div class="row">
										<v-text-field
											v-model="part.quantity"
											:disabled="part.retail == null || part.dealer == null"
											label="Quantity"
											hide-details="auto"
											outlined
											dense
											@keyup.enter.native="addPart(part)"/>

										<v-btn
											icon
											:disabled="part.retail == null || part.dealer == null"
											@click="addPart(part)">
											<v-icon>
												mdi-cart-plus
											</v-icon>
										</v-btn>
									</div>
								</td>
							</tr>
						</table>
					</div>

					<div class="add-part-buttons">
						<v-spacer />

						<v-btn
							color="error"
							@click="showAddPart = false">
							Close
						</v-btn>

						<v-spacer />
					</div>
				</div>
			</div>

			<v-spacer />
		</div>

		<!-- Cart Title Component -->
		<div class="title">
			Shopping Cart:
		</div>

		<!-- Cart Main Content Container Component -->
		<div class="contents">
			<!-- Left Column: All shipment Related Inputs. -->
			<div class="order-info">
				<!-- Location and Address Component -->
				<div class="scroll">
					<v-form
						ref="shipping"
						v-model="valid"
						lazy-validation>

						<div class="shipping">
							<h3>Shipping Information:</h3>

							<!-- Dropship Switch And Address Input -->
							<div class="row">
								<!-- Addresses Dropdown -->
								<div class="fields"
									style="margin-right: 5px;">
									<v-select
										v-model="address"
										:items="addresses"
										item-text="name"
										item-value="id"
										label="Dealer Location*"
										hide-details="auto"
										:rules="rules.location"
										return-object
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<v-tooltip
									bottom
									color="rgba(0,0,0,0)">
									<template v-slot:activator="{ on, attrs }">
										<div class="address-switch"
											v-bind="attrs"
											v-on="on">
											<v-switch
												v-model="dropship" 
												@change="updateCart()"
												label="Dropship?"/>
										</div>
									</template>

									<!-- Tooltip Pop-Up -->
									<div class="tooltip">
										<span style="font-size: 1.1em; font-weight: 600;">You can change between shipment types by toggling this switch:</span><br>
										- <span style="font-weight: 600;">Off:</span> Regular shipment to selected dealer location<br>
										- <span style="font-weight: 600;">On:</span> Direct shipment to your customer.<br><br>
										<span style="font-size: 1.1em; color: red; font-weight: 600;">Important Dropship Notes:</span><br>
										- Dropships are intended unit down situations with a $100.00 additional fee.<br>
										- Dropships from Canada to the US requires your customer's tax ID Number.<br>
										- Due to the inability for a dealer to verify order contents, Supreme isn't responsible for missing items on dropship.<br>
										- Supreme International can't guarantee shipping times on Dropship due to possible customs delays.<br>
									</div>
								</v-tooltip>
							</div>

							<!-- PO Input -->
							<div class="fields">
								<v-text-field
									v-model="po"
									label="Purchase Order*"
									hide-details="auto"
									:rules="rules.po"
									outlined
									dense
									@change="updateCart()"/>
							</div>

							<!-- Divider -->
							<div style="margin-bottom: 10px; border-bottom: 1px solid black;"
								v-if="dropship || address.address1 != ''">
							</div>

							<!-- Dealer Shipment Address -->
							<div class="dealer-ship"
								v-show="!dropship">
								<!-- Dealer Name Field -->
								<div class="fields"
									v-if="dealerName.length > 0">
									<v-text-field
										v-model="dealerName"
										label="Dealer Name"
										hide-details="auto"
										readonly
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- Address 1 Field -->
								<div class="fields"
									v-if="address.address1.length > 0">
									<v-text-field
										v-model="address.address1"
										label="Address 1"
										hide-details="auto"
										readonly
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- Address 2 Field -->
								<div class="fields"
									v-if="address.address2.length > 0">
									<v-text-field
										v-model="address.address2"
										label="Address 2"
										hide-details="auto"
										readonly
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- Address 3 Field -->
								<div class="fields"
									v-if="address.address3.length > 0">
									<v-text-field
										v-model="address.address3"
										label="Address 3"
										hide-details="auto"
										readonly
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- City Field -->
								<div class="fields"
									v-if="address.city.length > 0">
									<v-text-field
										v-model="address.city"
										label="City"
										hide-details="auto"
										readonly
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- State/Province, Country and Zip/Postal Fields -->
								<div class="row">
									<!-- State/Province Field -->
									<div class="fields"
										v-if="address.state.length > 0">
										<v-text-field
											v-model="address.state"
											label="State/Province"
											hide-details="auto"
											readonly
											outlined
											dense
											@change="updateCart()"/>
									</div>

									<!-- Country Field -->
									<div class="fields"
										style="margin-left: 5px;"
										v-if="address.country.length > 0">
										<v-text-field
											v-model="address.country"
											label="Country"
											hide-details="auto"
											readonly
											outlined
											dense
											@change="updateCart()"/>
									</div>

									<!-- Zip/Postal Field -->
									<div class="fields"
										style="margin-left: 5px;"
										v-if="address.postal.length > 0">
										<v-text-field
											v-model="address.postal"
											label="Zip/Postal Code"
											hide-details="auto"
											readonly
											outlined
											dense
											@change="updateCart()"/>
									</div>
								</div>
							</div>

							<!-- Drop Shipment Address -->
							<div class="drop-ship" 
								v-if="dropship">
								<!-- Address 1 Field -->
								<div class="fields">
									<v-text-field
										v-model="dropshipAddress.address1"
										label="Address 1*"
										hide-details="auto"
										:rules="rules.dropship"
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- Address 2 Field -->
								<div class="fields">
									<v-text-field
										v-model="dropshipAddress.address2"
										label="Address 2"
										hide-details="auto"
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- City Field -->
								<div class="fields">
									<v-text-field
										v-model="dropshipAddress.city"
										label="City*"
										hide-details="auto"
										:rules="rules.dropship"
										outlined
										dense
										@change="updateCart()"/>
								</div>

								<!-- State/Province, Country and Zip/Postal Fields -->
								<div class="row">
									<!-- State/Province Field -->
									<div class="fields">
										<v-text-field
											v-model="dropshipAddress.state"
											label="State/Province*"
											hide-details="auto"
											:rules="rules.dropship"
											maxlength="2"
											outlined
											dense
											@change="updateCart()"/>
									</div>

									<!-- Country Field -->
									<div class="fields"
										style="margin-left: 5px;">
										<v-text-field
											v-model="dropshipAddress.country"
											label="Country*"
											hide-details="auto"
											:rules="rules.dropship"
											maxlength="2"
											outlined
											dense
											@change="updateCart()"/>
									</div>

									<!-- Zip/Postal Field -->
									<div class="fields"
										style="margin-left: 5px;">
										<v-text-field
											v-model="dropshipAddress.postal"
											label="Zip/Postal Code*"
											hide-details="auto"
											:rules="rules.dropship"
											outlined
											dense
											@change="updateCart()"/>
									</div>
								</div>
							</div>
						</div>

						<!-- Contact Information Component -->
						<div class="contact">
							<h3>Contact Information:</h3>
							<!-- Contact Name Field -->
							<div class="fields">
								<v-text-field
									v-model="contact.name"
									label="Name*"
									hide-details="auto"
									:rules="rules.name"
									outlined
									dense
									@change="updateCart()"/>
							</div>

							<!-- Contact Email Field -->
							<div class="fields">
								<v-text-field
									v-model="contact.email"
									label="Email"
									hide-details="auto"
									:rules="rules.email"
									outlined
									dense
									@change="updateCart()"/>
							</div>

							<!-- Contact Phone Number Field -->
							<div class="fields">
								<v-text-field
									v-model="contact.phone"
									label="Phone*"
									hide-details="auto"
									:rules="rules.phone"
									outlined
									dense
									@change="handlePhoneNumber('contact')"/>
							</div>
						</div>
						
						<!-- Courier Information Component -->
						<div class="contact">
							<h3>Courier Details:</h3>

							<!-- Courier Selection Dropdown -->
							<div class="fields">
								<v-select
									v-model="courier"
									:items="couriers"
									item-text="name"
									item-value="id"
									label="Courier"
									hide-details="auto"
									return-object
									outlined
									dense
									@change="courierChanged()"/>
							</div>

							<!-- Courier Account Combobox -->
							<div class="fields"
								v-if="showCourierAccount">
								<v-combobox
									v-model="courierAccount"
									:items="getCourierAccounts"
									label="Courier Account Number*"
									item-text="account"
									item-value="account"
									hide-details="auto"
									autocomplete="off"
									:rules="rules.courierAccount"
									outlined
									dense 
									@change="saveCourierAccounts()" />
							</div>

							<!-- Pickup Name Field -->
							<div class="fields"
								v-if="showPickup">
								<v-text-field
									v-model="pickup.name"
									label="Pickup Name*"
									hide-details="auto"
									:rules="rules.name"
									outlined
									dense
									@change="updateCart()"/>
							</div>

							<!-- Pickup Phone Field -->
							<div class="fields"
								v-if="showPickup">
								<v-text-field
									v-model="pickup.phone"
									label="Pickup Phone Number*"
									hide-details="auto"
									:rules="rules.phone"
									outlined
									dense
									@change="handlePhoneNumber('pickup')"/>
							</div>

							<!-- Pickup Email Field -->
							<div class="fields"
								v-if="showPickup">
								<v-text-field
									v-model="pickup.email"
									label="Pickup Email"
									hide-details="auto"
									outlined
									dense
									@change="updateCart()"/>
							</div>

							<!-- Pickup Date Picker -->
							<div class="fields"
								v-if="showPickup">
								<v-menu
									v-model="datePicker"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="picker.date"
											label="Requested Pickup Date"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on" />
									</template>

									<v-date-picker
										v-model="picker.date"
										:allowed-dates="allowedDates"
										@input="updatePickupTime()"/>
								</v-menu>							
		  					</div>

							<!-- Pickup Time Picker -->
							<div class="fields"
								v-if="showPickup">
								<v-menu
									v-model="timePicker"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="picker.time"
											label="Requested Pickup Time <MST>"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on" />
									</template>

									<v-time-picker
										v-model="picker.time"
										ampm-in-title
										format="ampm"
										scrollable
										:allowed-hours="[9, 10, 11, 1, 2, 3]"
										:allowed-minutes="[0,30]"
										@input="updatePickupTime()"/>
								</v-menu>							
		  					</div>

							<!-- Urgency Selection Combobox -->
							<div class="row"
								style="border: 1px solid silver; border-radius: 5px; background-color: white;"
								v-if="showUrgency">
								<div class="fields">
									<v-checkbox
										label="Economy"
										v-model="urgency.economy"
										dense
										hide-details="auto"
										@change="changeUrgency('economy')" />
								</div>

								<div class="fields">
									<v-checkbox
										label="Express"
										v-model="urgency.express"
										dense
										hide-details="auto"
										@change="changeUrgency('express')" />
								</div>

								<div class="fields">
									<v-checkbox
										label="Over Night"
										v-model="urgency.overnight"
										dense
										hide-details="auto"
										@change="changeUrgency('overnight')" />
								</div>
							</div>
						</div>

						<!-- Order Notes Component -->
						<div class="contact">
							<h3>Order Notes:</h3>

							<v-textarea
								style="background-color: blanchedalmond; width: 100%;"
								outlined
								no-resize
								dense
								hide-details="auto"
								v-model="orderNote"
								@change="updateCart()"/>
						</div>
					</v-form>
				</div>
			</div>

			<!-- Right Column: All parts related Inputs -->
			<div class="parts-info">
				<!-- Top Title Row -->
				<div class="row"
					style="border: 1px solid gray; background-color: rgba(0,0,0,0.1); margin-bottom: 5px; padding: 5px; flex-grow: 0;">
					<h3>Parts List:</h3>
					<v-spacer />
					<div style="margin-left: 10px;">
						<v-btn
							color="primary"
							small
							@click="showAddPart = true">
							<v-icon>mdi-plus</v-icon>
							&nbsp;Add Part
						</v-btn>
					</div>
				</div>

				<!-- Parts List Container -->
				<div class="part-list">
					<div class="scroll"
						style="flex-grow: 1; height: 100%;">
						<!-- Parts List Table -->
						<table>
							<!-- Parts Header -->
							<thead class="fix-table-head">
								<td style="width: 5%; text-align: center;"><b>Quantity</b></td>
								<td style="width: 20%; text-align: center;"><b>Part Number</b></td>
								<td style="width: 30%; text-align: center;"><b>Description</b></td>
								<td style="width: 10%; text-align: center;"><b>Dealer</b></td>
								<td style="width: 10%; text-align: center;"><b>Retail</b></td>
								<td style="width: 10%; text-align: center;"><b>Dealer Total</b></td>
								<td style="width: 5%;"></td>
							</thead>

							<!-- Parts List Rows-->
							<tr style="background-color:aliceblue;"
								v-for="part, index in getParts"
								:key="index">
								<td>
									<v-text-field
										style="background-color: white;"
										v-model="part.quantity"
										type="number"
										min="1"
										hide-details="auto"
										:rules="[rules.quantity]"
										outlined
										dense
										@change="quantityChanged(part)" />
								</td>
								<td>{{part.part}}</td>
								<td>{{part.description}}</td>
								<td>${{ parseFloat(part.dealer).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
								<td>${{ parseFloat(part.retail).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
								<td>${{ (parseFloat(part.dealer).toFixed(2) * parseFloat(part.quantity)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
								<td>
									<v-btn
										icon
										color="red"
										@click="removePart(index)">
										<v-icon>
											delete
										</v-icon>
									</v-btn>
								</td>
							</tr>

							<!-- Dropship Fee Part Line -->
							<tr	v-if="dropship">
								<td >
									<v-text-field
										style="background-color: white;"
										value="1"
										hide-details="auto"
										readonly
										outlined
										dense/>
								</td>
								<td>SURCHARGE</td>
								<td>DROPSHIP ORDER DIRECT TO CUSTOMER FEE</td>
								<td>$100.00</td>
								<td>$100.00</td>
								<td>$100.00</td>
								<td></td>
							</tr>

							<!-- Totals Bottom Footer -->
							<tfoot class="fix-table-foot"
								v-if="getParts.length > 0">
								<td>{{ this.orderTotals.quantity}}</td>
								<td></td><td></td><td></td>
								<td>Total Cost:</td>
								<td>${{ parseFloat(this.orderTotals.total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</td>
								<td></td>
							</tfoot>
						</table>
					</div>
				</div>

				<!-- Botom Buttons -->
				<div class="row"
					style="border: 1px solid gray; padding: 5px; flex-grow: 0;">
					<v-spacer />
					
					<div class="fields">
						<v-btn
							color="success"
							:disabled="!canSubmit || !valid"
							@click="submitOrder()">
							Place Order
						</v-btn>
					</div>

					<div class="fields">
						<v-btn
							color="error"
							@click="clearCart(true)">
							Clear Cart
						</v-btn>
					</div>

					<v-spacer />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { EventBus } from '../../javascript/event-bus'

export default {
	name: 'Cart',
	components: {
	},
	props: {
	},
	data () {
		return {
			po: "",
			discount: 0,
			dealerName: "",
			contact: {
				name: "",
				email: "",
				phone: "",
				fedTaxID: "",
			},
			pickup: {
				customer: false,
				name: "",
				phone: "",
				time: ""
			},
			addresses: [],
			address: {
				address1: "",
				address2: "",
				address3: "",
				city: "",
				state: "",
				country: "",
				postal: "",
			},
			dropship: false,
			dropshipAddress: {
				address1: "",
				address2: "",
				address3: "",
				city: "",
				state: "",
				country: "",
				postal: "",
			},
			orderNote: "",
			couriers: this.$config.couriers.names,
			courier: "Most Economical",
			courierAccounts: [],
			courierAccount: "",
			urgency: {
				economy: true,
				express: false,
				overnight: false,
			},

			loading: false,
			showComplete: false,
			completeMessage: "",
			completeError: false,
			orderID: 0,

			// Pickup Date Time Picker
			datePicker: false,
			timePicker: false,
			allowedDates: val => new Date(val).getDay() <= 4,
			picker : {
				date: "",
				time: "10:00:00",
			},

			showAddPart: false,
			searchMessage: "",
			searchNumber: "",
			searchResults: [],

			// Form Rules
			valid: true,
			rules: {
				quantity: v => v > 0 || '',
				email: [
					v => !!v || 'E-mail is required',
					v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
				],
				phone: [
					v => /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/.test(v) || 'Phone Number Invalid. Format (xxx) xxx-xxxx'
				],
				names: [
					v => !!v || 'Name is required',
					v => (v && v.length >= 3) || 'Name must have more than 2 characters',
				],
				po: [
					v => !!v || 'PO is required',
					v => (v && v.length > 3) || 'Name must have more than 3 characters',
				],
				dropship: [
					v => !!v || 'Required Field'
				],
				location: [
					v => v.address1.length > 0 || 'Dealer Location Required'
				],
				courierAccount: [
					v => !!v || 'Courier Account Required',
					v => (v && v.length >= 3) || 'Account must have more than 2 characters',
				],
			},
		}
	},
	methods: {
		submitOrder() {
			if (!this.$refs.shipping.validate()){
				return
			}

			this.loading = true

			var formData = new FormData();
			var courier = {name: this.courier, account: this.courierAccount}

			var urgency = ""
			if (this.urgency.overnight) {
				urgency = "Overnight"
			} else if (this.urgency.express) {
				urgency = "Express"
			} else {
				urgency = "Economy"
			}

			// Credentials
			formData.append("username", this.$store.getters["authentication/GET_CREDENTIALS"].username)
			formData.append("token", this.$store.getters["authentication/GET_CREDENTIALS"].token)

			// API DATA
			formData.append("po", this.po)
			formData.append("discount", this.discount == null ? 0 : this.discount)
			formData.append("dealername", this.userInfo.vid)
			formData.append("contact", JSON.stringify(this.contact))
			formData.append("pickup", JSON.stringify(this.pickup))
			formData.append("address", JSON.stringify(this.address))
			formData.append("dropship", this.dropship)
			formData.append("dropshipaddress", JSON.stringify(this.dropshipAddress))
			formData.append("ordernote", this.orderNote)
			formData.append("courier", JSON.stringify(courier))
			formData.append("urgency", urgency)
			formData.append("parts", JSON.stringify(this.getParts))
			formData.append("name", this.$store.getters["authentication/GET_NAME"])

			axios.post(`${this.$config.api}/order.php?cmd=submitOrder`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					if (response.data.action == "login"){
						this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
						this.$store.dispatch('authentication/SET_CREDENTIALS', {})

						EventBus.$emit('invalidLogin')
						return
					}

					this.loading = false
					this.completeTitle = `<h3>Order Failed To Submit</h3>`
					this.completeMessage = `Order failed to submit with error message: ${response.data.error}`
					this.completeError = true
					this.showComplete = true
					this.bannerTimer(2)

					return
				}

				// API Success
				this.loading = false
				this.completeTitle = `<h3>Order #${response.data.orderID} submitted successfully!</h3>`
				this.completeMessage = "You should get a confirmation email shortly.<br>Please review the order and inform us of any errors."
				this.completeError = false
				this.showComplete = true
				EventBus.$emit('orderRefresh')
				this.bannerTimer(2)
			})
			.catch(e => {
				console.log(`cart.vue: submitOrder - API Error (${e})`)
				this.loading = false;
			})
		},
		searchParts() {
			if (this.searchNumber.length < 3){
				this.searchMessage = "Must enter 3 or more characters."
				return
			}

			this.loading = true
			this.searchResults = []

			var formData = new FormData();
			// Credentials
			formData.append("username", this.$store.getters["authentication/GET_CREDENTIALS"].username)
			formData.append("token", this.$store.getters["authentication/GET_CREDENTIALS"].token)

			// API DATA
			formData.append("part", this.searchNumber)
			formData.append("vid", this.$store.getters['authentication/GET_VID'])

			axios.post(`${this.$config.api}/order.php?cmd=searchParts`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					if (response.data.action == "login"){
						this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
						this.$store.dispatch('authentication/SET_CREDENTIALS', {})

						EventBus.$emit('invalidLogin')
						return
					}

					this.loading = false
					this.searchMessage = "No parts found."
					return
				}

				// API Success
				this.loading = false
				this.searchMessage = ""
				this.searchResults = response.data.parts
			})
			.catch(e => {
				console.log(`cart.vue: submitOrder - API Error (${e})`)
				this.loading = false;
			})
		},
		addPart(part) {
			this.$store.dispatch("cart/ADD_PART", part)
			this.writeCartCookie()
		},
		getDealerAddresses() {
			this.addresses = []

			var formData = new FormData();
			// Credentials
			formData.append("username", this.$store.getters["authentication/GET_CREDENTIALS"].username)
			formData.append("token", this.$store.getters["authentication/GET_CREDENTIALS"].token)

			// API DATA
			formData.append("vid", this.$store.getters['authentication/GET_VID'])
			
			axios.post(`${this.$config.api}/order.php?cmd=getDealerAddresses`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED

					if (!response.data.success){
						if (response.data.action == "login"){
							this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
							this.$store.dispatch('authentication/SET_CREDENTIALS', {})

							EventBus.$emit('invalidLogin')
							return
						}

						return
					}

					// API Success
					this.addresses = response.data.addresses
					//console.log(response.data)

				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		getDiscount() {
			this.addresses = []

			var formData = new FormData();
			// Credentials
			formData.append("username", this.$store.getters["authentication/GET_CREDENTIALS"].username)
			formData.append("token", this.$store.getters["authentication/GET_CREDENTIALS"].token)

			// API DATA
			formData.append("vid", this.$store.getters['authentication/GET_VID'])
			
			axios.post(`${this.$config.api}/order.php?cmd=getDealerDiscount`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED
					if (!response.data.success){
						if (response.data.action == "login"){
							this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
							this.$store.dispatch('authentication/SET_CREDENTIALS', {})

							EventBus.$emit('invalidLogin')
							return
						}

						this.$store.dispatch("cart/SET_DISCOUNT", 0)
						return
					}

					// API Success
					this.discount = response.data.discount
					this.$store.dispatch("cart/SET_DISCOUNT", this.discount)
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		courierChanged() {
			this.courierAccount = ""
			this.updateCart()
		},
		saveCourierAccounts() {
			if (this.courierAccount == null || this.courierAccount == ""){
				return
			}

			var match = false

			for (var account of this.courierAccounts) {
				if (account.account == this.courierAccount) {
					match = true
				}
			}

			if (!match) {
				var courierAccount = {
					courier: this.courier,
					account: this.courierAccount
				}

				this.courierAccounts.push(courierAccount)

				this.$cookie.set("courierAccounts", JSON.stringify(this.courierAccounts), 20*365)
			}

			this.updateCart()
		},
		changeUrgency(urgency) {
			switch(urgency) {
				case "economy":
					this.urgency.economy = true
					this.urgency.express = false
					this.urgency.overnight = false

					break
				case "express":
					this.urgency.economy = false
					this.urgency.express = true
					this.urgency.overnight = false

					break
				case "overnight":
					this.urgency.economy = false
					this.urgency.express = false
					this.urgency.overnight = true

					break
				default:
					console.log(`Unknown urgency state: ${urgency}`)
					break
			}

			this.updateCart()
		},
		clearCart(all){
			if (all) {
				this.dropship = false
				this.orderNote= ""
				this.dealerName = ""
				this.courierAccount = ""
				this.courier = "Most Economical"
				this.po = ""
				this.address = {
					address1: "",
					address2: "",
					address3: "",
					city: "",
					state: "",
					country: "",
					postal: "",
				}
				this.dropshipAddress = {
					address1: "",
					address2: "",
					address3: "",
					city: "",
					state: "",
					country: "",
					postal: "",
				}
				this.urgency = {
					economy: true,
					express: false,
					overnight: false,
				}
				this.contact = {
					name: this.userInfo.name,
					email: this.userInfo.email,
					phone: this.userInfo.phone
				}
				this.pickup = {
					customer: false,
					name: "",
					phone: "",
					time: ""
				}

				const currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
				this.picker.date = currentDate.toISOString().split("T")[0]
				this.picker.time = "10:00"

				this.$store.dispatch("cart/CLEAR_CART")
				this.$refs.shipping.resetValidation()
			} else {
				this.$store.dispatch("cart/CLEAR_PARTS")
			}

			this.updateCart()
		},
		removePart(index) {
			this.$store.dispatch("cart/REMOVE_PART", index)
			this.updateCart()
		},
		quantityChanged(part) {
			if (part.quantity < 1) {
				part.quantity = 1
			}

			this.$store.dispatch("cart/EDIT_PART", part)
			this.updateCart()
		},
		updateCart() {
			var discount = this.$store.getters['authorization/GET_DISCOUNT']

			// Is this a pickup order
			if (this.courier != 'Dealer Pickup/Arranging') {
				this.pickup = {
					customer: false,
					name: "",
					phone: "",
					time: ""
				}
			}

			// Is this a dropship order
			if (!this.dropship) {
				this.dropshipAddress = {
					address1: "",
					address2: "",
					address3: "",
					city: "",
					state: "",
					country: "",
					postal: "",
				}
			} else {
				if (this.dropshipAddress.country !== null) {
					this.dropshipAddress.country = this.dropshipAddress.country.toUpperCase()
				}
				if (this.dropshipAddress.city !== null) {
					this.dropshipAddress.city = this.dropshipAddress.city.toUpperCase()
				}
				if (this.dropshipAddress.postal !== null) {
					this.dropshipAddress.postal = this.dropshipAddress.postal.toUpperCase()
				}
			}

			if (!this.showCourierAccount) {
				this.courierAccount = ""
			}

			var cart = {
				parts: this.getParts,
				po: this.po,
				dealerName: this.dealerName,
				orderNote: this.orderNote,

				discount: discount == null ? 0 : discount,
				contact: this.contact,
				courier: {
					name: this.courier,
					account: this.courierAccount
				},
				urgency: this.urgency,
				pickup: this.pickup,
				address: this.address,
				dropship: this.dropship,
				dropshipAddress: this.dropshipAddress,
			}
			
			this.$refs.shipping.resetValidation()
			this.$store.dispatch("cart/SET_CART", cart)
			this.writeCartCookie(cart)
		},
		updatePickupTime() {
			this.datePicker = false
			this.timePicker = false
			this.pickup.time = this.picker.date + " " + this.picker.time + ":00"
			this.updateCart
		},
		writeCartCookie() {
			this.$cookie.set("cart", JSON.stringify(this.$store.getters["cart/GET_CART"]), 14)
		},
		bannerTimer(timeout = 3) {
			setTimeout(this.clearBanner, timeout * 1000)		
		},
		clearBanner() {
			if (!this.completeError) {
				this.clearCart(true)
			}

			this.showComplete = false
			this.completeMessage = ""
			this.completeTitle = ""
			this.completeError = false
		},
		handlePhoneNumber(field) {
			if (field == "contact") {
				const digits = this.contact.phone.replace(/\D/g,'').substring(0,10)
				const areaCode = digits.substring(0,3)
				const prefix = digits.substring(3,6)
				const suffix = digits.substring(6,10)

				if(digits.length > 6) {
					this.contact.phone = `(${areaCode}) ${prefix}-${suffix}`
				}
				else if(digits.length > 3) {
					this.contact.phone = `(${areaCode}) ${prefix}`
				}
				else if(digits.length > 0) {
					this.contact.phone = `(${areaCode}`
				}
			}

			if (field == "pickup") {
				const digits = this.pickup.phone.replace(/\D/g,'').substring(0,10)
				const areaCode = digits.substring(0,3)
				const prefix = digits.substring(3,6)
				const suffix = digits.substring(6,10)

				if(digits.length > 6) {
					this.pickup.phone = `(${areaCode}) ${prefix}-${suffix}`
				}
				else if(digits.length > 3) {
					this.pickup.phone = `(${areaCode}) ${prefix}`
				}
				else if(digits.length > 0) {
					this.pickup.phone = `(${areaCode}`
				}
			} 
			this.updateCart()
		},
	},
	computed: {
		userInfo() {
			return this.$store.getters['authentication/GET_USERINFO']
		},
		showCourierAccount() {
			var show = false

			for (var courier of this.$config.couriers.showAccountField) {
				if (this.courier == courier) {
					show = true
				}
			}

			return show
		},
		getCourierAccounts() {
			var accounts = []

			for (var account of this.courierAccounts) {
				//console.log(`${account.courier} ? ${this.courier}`)
				if (account.courier == this.courier) {
					//console.log("Courier Account Match")
					accounts.push(account.account)		
				}
			}

			return accounts
		},
		showUrgency() {
			return !this.$config.couriers.hideUrgency.includes(this.courier)
		},
		showPickup() {
			return this.courier == 'Dealer Pickup/Arranging'
		},
		getParts() {
			return this.$store.getters['cart/GET_PARTS']
		},
		canSubmit() {
			// Parts amount check
			if (this.getParts.length == 0) {
				return false
			}

			return true
		},
		partLineTotal() {
			return 0.00
		},
		orderTotals() {
			var total = 0.00
			var quantity = 0

			if (this.dropship == true) {
				total += 100.00
			}

			for (var part of this.getParts) {
				if (part.quantity < 1) {
					part.quantity = 1
				}

				total += parseFloat(part.dealer).toFixed(2) * part.quantity
				quantity += parseFloat(part.quantity)
			}
			
			if (isNaN(total)) {
				total = 0.00
			}

			return {
				total: total,
				quantity: quantity
			}
		},
		completeBanner() {
			if (this.completeError) {
				return "background-color: red; color: white; width: 100%; border-bottom: 1px solid silver; padding: 5px;"
			} else {
				return "background-color: #99ccff; color: black; width: 100%; border-bottom: 1px solid silver; padding: 5px;"
			}
		},
		pickupType() {
			if (this.pickup.customer) {
				return "Pickup Type: Customer Pickup, Hold Paperwork"
			}

			return "Pickup Type: Dealer Pickup, Include Paperwork"
		},
	},
	mounted() {
		this.getDiscount()
		this.getDealerAddresses()

		const currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
		this.picker.date = currentDate.toISOString().split("T")[0]

		this.contact = {
			name: this.userInfo.name,
			email: this.userInfo.email,
			phone: this.userInfo.phone
		}


		if (this.$cookie.get("courierAccounts")){
			this.courierAccounts = JSON.parse(this.$cookie.get("courierAccounts"))
		}

		if (this.$cookie.get("cart")) {
			var cart = this.$store.getters["cart/GET_CART"]

			this.address = cart.address
			this.po = cart.po
			this.dropship = cart.dropship
			this.dropshipAddress = cart.dropshipAddress
			this.courier = cart.courier.name
			this.urgency = cart.urgency
			this.contact = cart.contact
			this.pickup = cart.pickup
			this.dealerName = cart.dealerName
			this.orderNote = cart.orderNote
			this.courierAccount = cart.courier.account == null ? "" : cart.courier.account
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.cart {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background-color: lightgray;
		border: 2px solid black;
		border-radius: 5px;
	}

	.centered {
		text-align: center;
	}

	.title {
		font-size: 24pt;
		text-align: left;
		border: 1px solid darkgrey;
		color: white;
		background-color: #cf2e2e;
		padding: 5px;
		width: 100%;
	}

	.contents {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		width: 100%;
		padding: 5px;
		overflow: hidden;
		background-color: white;
	}
	
	.order-info {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 30vw;
		border: 1px solid gray;
		border-radius: 5px;
		padding: 5px;
		overflow: hidden;
	}

	.scroll {
		height: 100%;
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.parts-info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		height: 100%;
		border: 1px solid gray;
		border-radius: 5px;
		padding: 5px;
		margin-left: 5px;
		overflow: hidden;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}
		
	.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
		
	.shipping {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: 10vh;
		padding: 5px;
		border: 1px solid silver;
		background-color: rgba(0,0,0,0.1);
		margin-bottom: 5px;

	}

	.contact {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		min-height: 10vh;
		padding: 5px;
		border: 1px solid silver;
		background-color: rgba(0,0,0,0.1);
		margin-bottom: 5px;

	}

	.fields {
		margin-bottom: 10px;
		background-color: white;
		width: 100%;
	}

	.tooltip {
		width: 30wv;
		max-height: 70vh;
		padding: 5px;
		border: 1px solid gray;
		border-radius: 5px;
		background-color: aliceblue;
		color: black;
		font-size: 1.1em;
	}

	.part-column {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center; 
		border: 1px solid black;
		padding: 5px;
		min-height: 20px;
		height: auto;
		overflow: hidden;
	}	

	.cart-line {
		display: flex;
		flex-direction: row;
		width: 100%;
		min-height: 30px;
		margin-top: 1px;
		margin-bottom: 1px;
	}

	.part-list {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		border: 1px solid gray; 
		background-color: rgba(0,0,0,0.1); 
		margin-bottom: 5px; 
		padding: 5px; 
		flex-grow: 1;
	}

	.loading {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 33px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 5;
	}

	.order-complete {
		width:500px;
		max-height: 500px;
		border: 1px solid gray;
		border-radius: 10px;
		background-color: azure;
		text-align: center;
		overflow: hidden;
	}

	.order-message {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 5px;
	}

	tr, td {
		padding: 5px;
		border: 1px solid black;
	}	

	table {
		width: 100%;
		font-size: 1.5vh;
	}

	.fix-table-head {
		position: sticky;
		top: 0;
		background-color:lightgreen;
		z-index: 1;
	}
        
	.fix-table-foot {
		position: sticky;
		bottom: 0;
		background-color: #ccffff;
	}

	.add-part {
		display: flex;
		flex-direction: column;
		width:60vw;
		max-height: 70vh;
		border: 1px solid gray;
		border-radius: 10px;
		background-color: azure;
		text-align: center;
		overflow: hidden;
	}

	.add-part-banner {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #99ccff; 
		color: black; 
		width: 100%; 
		border-bottom: 1px solid silver; 
		padding: 5px;
	}

	.add-part-container {
		display: flex;
		flex-direction: column;

		width: 100%;
		height: 100%;
		overflow: hidden;
		padding: 5px;
	}

	.search-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-shrink: 1;
		width: 100%;
		padding: 5px;
		margin-bottom: 5px;;
		border: 1px solid silver;
	}

	.search-results {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		flex-grow: 1;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.add-part-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-shrink: 1;
		margin-top: 5px;
		padding: 5px;
		width: 100%;
		height: 100%;
		border: 1px solid silver;
	}
</style>