<template>
	<div class="core"
		v-resize="onResize">
		<!-- Update popup -->
		<div class="update-container"
			v-if="showUpdate"
			@click="showUpdate = false">
			<v-spacer />

			<div class="update-inner">
				<div class="close-button">
					<v-btn
						color="default"
						icon
						@click="showUpdate = false">
						<v-icon
							large
							color="error">
							mdi-close-circle
						</v-icon>
					</v-btn>
				</div>
				<update />
			</div>

			<v-spacer />
		</div>

		<!-- Cart Window -->
		<div class="update-container"
			v-if="showCart && this.$store.getters['authentication/GET_AUTHENTICATED']">
			<div class="modal-window"
				v-show="showCart">
				<div class="close-button">
					<v-btn
						color="default"
						icon
						@click="closeCart()">
						<v-icon
							large
							color="white"
							light>
							mdi-close-circle
						</v-icon>
					</v-btn>
				</div>

				<cart />
			</div>

			<v-spacer />
		</div>

		<!-- Part PDF Window -->
		<div class="update-container"
			v-show="parseInt(this.pdfID) != -1">
			<div class="modal-window">
				<div class="close-button">
					<v-btn
						icon
						@click="pdfID = -1">
						<v-icon
							large
							color="error">
							mdi-close-circle
						</v-icon>
					</v-btn>
				</div>

				<component
					v-if="parseInt(this.pdfID) != -1"
					:is="'PartPdf'"
					:pdfID="this.pdfID">
				</component>
			</div>

			<v-spacer />
		</div>

		<!-- Main Page Title Component -->
		<div class="title center">
			<div class="title-image-container">
				<img
					class="title-image" 
					src="../assets/SUPREME-LOGO.png" />
			</div>

			<v-spacer />
			
			<h2>Supreme International Dealer Tools</h2>

			<v-spacer />

			<div class="login">
				<v-btn v-if="!credentials.authenticated"
					text
					color="white"
					@click="showLogin()">
					Log In
				</v-btn>

				<!-- Login and Cart display -->
				<div class="row"
					 v-if="credentials.authenticated">
					{{credentials.username}}

					<!-- Logout Button -->
					<v-btn
						icon
						color="white"
						@click="logout()">
						<v-icon>
							logout
						</v-icon>
					</v-btn>
				</div>


				<v-tooltip
					start
					color="rgba(0,0,0,0)"
					:disabled="cartCount <= 0"
					v-if="credentials.authenticated">
					<template v-slot:activator="{ on, attrs }">
						<div class="cart-button"
							v-bind="attrs"
							v-on="on">
							<!-- Cart Button -->
							<v-btn
								x-large
								icon
								dark
								@click="openCart()">
								<v-icon
									x-large
									color="white">
									mdi-cart-arrow-right
								</v-icon>

								<div class="cart-badge"
									v-if="cartCount > 0">
									<v-badge
										bordered
										small
										color="primary" 
										:content="cartCount"
										offset-x="15"
										offset-y="-9"/>
								</div>
							</v-btn>
						</div>
					</template>

					<!-- Tooltip Pop-Up -->
					<div class="cart-tooltip">
						<div style="width: 100%; border-bottom: 1px solid black;">
							<h3>Shopping Cart:</h3>
						</div>

						<!-- Parts list container-->
						<div class="cart-list">
						<!-- Header of parts list -->
							<div class="cart-line"
								style="background-color: lightblue; margin-top: 5px;">
								<div class="cart-column" 
									style="width: 10%;">
									QTY
								</div>

								<div class="cart-column" 
									style="width: 20%;">
									Part
								</div>

								<div class="cart-column" 
									style="width: 40%;">
									Description
								</div>

								<div class="cart-column" 
									style="width: 15%;">
									Cost
								</div>

								<div class="cart-column" 
									style="width: 15%;">
									Total
								</div>
							</div>

							<div class="cart-line"
								v-for="part, index in cartItems"
								:key="index">
								<div class="cart-column" 
									style="width: 10%;">
									{{part.quantity}}
								</div>

								<div class="cart-column" 
									style="width: 20%;">
									{{part.part}}
								</div>

								<div class="cart-column" 
									style="width: 40%;">
									{{part.description}}
								</div>

								<div class="cart-column" 
									style="width: 15%;">
									${{getDealer(part.retail).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
								</div>

								<div class="cart-column" 
									style="width: 15%;">
									${{ (getDealer(part.retail) * part.quantity).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
								</div>
							</div>
						</div>
					</div>
				</v-tooltip>
			</div>
		</div>

		<!-- Main Page Tabs Component And Contents -->
		<v-tabs
			class="tabs"
			v-model="tab"
			height="30px"
			slider-size="2"
			color="black"
			background-color="#cf2e2e"
			ripple>
			<v-tabs-slider color="white" />

			<v-tab
				class="core-tabs"
				v-for="item in tabs"
				:key="item.name"
				@click="tabClicked(item.ref)">
				<h3>{{ item.name }}</h3>
			</v-tab>

			<v-tabs-items
				v-model="tab">
				<v-tab-item
					v-for="item in tabs"
					:key="item.name"
					eager>
					<div
						class="tab-wrapper">
						<component
							:ref="item.ref" 
							:is="item.component"
							@showUpdate="showUpdate = true"
							@part-clicked="partClicked"/>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import { version } from '../../package.json'		// Imported current version build from package.json.
require("@/assets/dealer_background.jpg")
import { EventBus } from '../javascript/event-bus'

// Imported vuejs components
import Update from './update'						// Imports update component which is used as help and version information.
import PartCatalogue from './catalogue/catalogue'						// Imports update component which is used as help and version information.
import SerialSearch from './serial_search'			// Imports Serial Number Search component which adds part searching functionality.
import PartSearch from './part_search'				// Imports Parts Search component which adds part searching functionality.
import Documents from './documents'					// Imports Documents component which loads commonly used PDFs.
import Orders from './ordering/orders'						// Imports Orders component which shows past orders and statuses.
import Cart from './ordering/cart'							// Imports shopping cart component.
import PartPdf from './part_pdf'					// Imports Part PDF component used to a parts PDF and part list.

export default {
	name: 'Core',
	components: {
		Update,
		PartCatalogue,
		PartSearch,
		SerialSearch,
		Documents,
		Orders,
		Cart,
		PartPdf,
	},
	data () {
		return {
			/* Version data used to decide whether to show update component. */
			currVersion: version,		// Current version set from package.json.
			lastVersion: '',			// Last version loaded by user. Set by reading cookie.
			showUpdate: false,			// Toggles update visibility through version mismatch or "Help" button.

			// Tab Varaibles
			tab: 0,
			//TODO: Remove Documents from pub tabs when live
			pubTabs: [
				{
					name: 'Serial Number Search',
					component: 'serial-search',
					ref: 'serial'
				},
				{
					name: 'Part Number Search',
					component: 'part-search',
					ref: 'part'
				},
				{
					name: 'Documents',
					component: 'documents',
					ref: 'documents'
				},
			],
			dealerTabs: [
				{
					name: 'Parts Catalogue',
					component: 'part-catalogue',
					ref: 'catalogue'
				},
				{
					name: 'Serial Number Search',
					component: 'serial-search',
					ref: 'serial'
				},
				{
					name: 'Part Number Search',
					component: 'part-search',
					ref: 'part'
				},
				{
					name: 'Documents',
					component: 'documents',
					ref: 'documents'
				},
				{
					name: 'Orders',
					component: 'orders',
					ref: 'orders'
				},
			],

			// PDF Variables
			pdfID: -1,

			// Cart Variables
			showCart: false,
		}
	},
	methods: {
		getDiscount() {
			var formData = new FormData();
			// Credentials
			formData.append("username", this.$store.getters["authentication/GET_CREDENTIALS"].username)
			formData.append("token", this.$store.getters["authentication/GET_CREDENTIALS"].token)

			// API DATA
			formData.append("vid", this.$store.getters["authentication/GET_VID"])

			axios.post(`${this.$config.api}/order.php?cmd=getDealerDiscount`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED
					if (!response.data.success){
						console.log("Core: Failed to get discount.")
						return
					}

					// API SUCCESS
					this.$store.dispatch("cart/SET_DISCOUNT", response.data.discount)
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		onResize() {
			this.$el.style.height = window.innerHeight + "px"
			this.$el.style.maxHeight = window.innerHeight + "px"
		},
		partClicked(id, part) {
			this.tab = 1

			this.$refs.part[0].partNumber = part
			this.$refs.part[0].searchPart(id)
		},
		showLogin() {
			this.$store.dispatch("authentication/SET_SHOWLOGIN", true)
		},
		logout() {
			var clear = {
				authenticated: false,
				first: "",
				last: "",
				username: "",
				token: "",
			}

			this.$cookie.set("credentials", JSON.stringify(clear), 30)
			this.$store.dispatch("authentication/SET_CREDENTIALS", clear)
		},
		setCredentials(credentials, userInfo) {
			this.$store.dispatch("authentication/SET_CREDENTIALS", credentials)
			this.$store.dispatch("authentication/SET_USERINFO", userInfo)
		},
		openCart() {
			this.showCart = true
		},
		closeCart() {
			this.showCart = false		
		},
		showPDF(pdf) {
			if (pdf == null || pdf == -1) {
				return
			}

			this.pdfID = pdf
		},
		handleKey(event) {
			if (event.keyCode === 27) {
				this.pdfID = -1
			}
		},
		invalidLogin() {	// An invalid login signal has been sent, set tab to 0 and clear authentication information in store and cookie
			this.logout()
			this.tab = 0
		},
		tabClicked(tab) {
			// Check what tab was clicked and tell it to refresh it's contents
			switch(tab) {
				case "orders":
					EventBus.$emit('orderRefresh')
					break
				case "documents":
					EventBus.$emit('documentRefresh')
					break
			}
		},
		getDealer(retail) {
			var decimal = this.$store.getters['cart/GET_DISCOUNT'].decimal
			var dealer = (parseFloat(retail) * parseFloat(decimal)).toFixed(2)
			console.log(`Dealer: ${dealer} Decimal: ${decimal}`)
			return dealer
		},
	},
	computed: {
		cartCount() {
			return this.$store.getters['cart/GET_PARTS'].length
		},
		credentials() {
			return this.$store.getters['authentication/GET_CREDENTIALS']
		},
		tabs() {
			if (this.credentials.authenticated){
				return this.dealerTabs
			} else {
				//return this.dealerTabs
				return this.pubTabs
			}
		},
		cartItems() {
			return this.$store.getters['cart/GET_PARTS']
		},
	},
	created() {
		// Get credentials and user information from their cookies.
		var credentials = {}
		var userInfo = {}

		if (this.$cookie.get("credentials")){
			credentials = JSON.parse(this.$cookie.get("credentials"))
		}

		if (this.$cookie.get("userinfo")){
			userInfo = JSON.parse(this.$cookie.get("userinfo"))
		}
		
		this.setCredentials(credentials, userInfo)
		
		// Handle version cookie
		if (this.$cookie.get("version")){
			this.lastVersion = this.$cookie.get("version")
		}

		// get cart cookie
		if (this.$cookie.get("cart")) {
			this.$store.dispatch("cart/SET_CART", JSON.parse(this.$cookie.get("cart")))
		}

		if (this.$store.getters["authentication/GET_AUTHENTICATED"] && this.$store.getters["authentication/GET_VID"] != null && this.$store.getters["authentication/GET_VID"].length > 0) {
			this.getDiscount()
		}

		if (this.lastVersion.length > 0 ) {
			if (this.lastVersion != this.currVersion) {
				this.$cookie.set("version", this.currVersion, 20*365)
				//this.showUpdate = true
			}
		} else {
			this.$cookie.set("version", this.currVersion, 20*365)
			this.showUpdate = true
		} 
	},
	mounted() {
		this.$el.style.height = window.innerHeight + "px"
		this.$el.style.maxHeight = window.innerHeight + "px"

		// User keyboard input check for "Escape" key.
		document.addEventListener('keyup', this.handleKey)

		// Event Bus Actions
		EventBus.$on('showPDF', this.showPDF)
		EventBus.$on('invalidLogin', this.invalidLogin)

		let urlParams = new URLSearchParams(window.location.search)

		if (this.$store.getters["authentication/GET_AUTHENTICATED"]) {
			this.tab = 1
		}

		if(urlParams.has('part')){
			if (urlParams.get('part').length > 0){
				this.tab = 2
			}
		}

		if(urlParams.has('serial')){
			if (urlParams.get('serial').length > 0){
				this.tab = 1
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.core {
		position: absolute;
		display: flex;
		flex-flow: column;
		width: 100%;
		height: 400px;
		overflow: hidden;
		background: rgba(0,0,0,0);
	}

	.update-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 100;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0px;
		top: 0px;
		background: rgba(0,0,0,.25);
		text-align: center;
	}

	.login-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.update-inner {
		height: 700px;
		width: 1000px;
	}

	.close-button {
		position: relative;
		float: right;
		border-radius: 5px;
		top:42px;
		right: 10px;
	}

	.centered {
		text-align: center;
	}

	.title {
		flex-shrink: 1;
		font-size: 32pt;
		width: 100%;
		min-height: 80px;
		padding: 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #cf2e2e;
		color: white;
	}

	.title-image {
		max-height: 100%;
		object-fit: contain;
	}

	.title-image-container {
		position: absolute;
		height: 70px;
		left: 5px;
		top: 5px;
	}

	.tab-wrapper {
		height: calc(100vh - 100px);
		width: 100%;
		border: 1px solid gray;
		border-top: 0px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		background: rgba(0,0,0,0.1);
	}

	.v-window-item {
		background-image: url("@/assets/dealer_background.jpg");
		background-size:     cover;
		background-repeat:   no-repeat;
		background-position: left center;
	}

	.tabs {
		height: 100%;
		margin-top: -10px;
	}

	.v-tab--active {
		background-color: white !important;
	}

	.core-tabs {
		background-color: blanchedalmond;
		border: 1px solid gray;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		margin-top: 5px;
		margin-bottom: 0px;
	}

	.login {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 0px;
		right: 0px;
		height: 75px;
		padding: 5px;
		font-size: 1.5vh;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.cart-button {
		margin-left: 10px;
	}

	.cart-badge {
		float:left;
	}

	.username {
		display: flex;
		flex-direction: column;
	}

	.cart-tooltip {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 60vw;
		max-height: 80vh;
		padding: 5px;
		color: black;
		background-color: white;
		border: 1px solid black;
		border-radius: 5px;
	}

	.cart-list {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.cart-column {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center; 
		border: 1px solid black;
		padding: 5px;
		min-height: 20px;
		height: auto;
		overflow: hidden;
	}

	.cart-line {
		display: flex;
		flex-direction: row;
		width: 100%;
		min-height: 30px;
		margin-top: 1px;
		margin-bottom: 1px;
	}

	.modal-window {
		position: absolute;
		height: 92vh;
		width: 100vw;
	}
</style>