export default {
	namespaced: true,
	state: {
		showLogin: false,

		userInfo: {
			name: "",
			email: "",
			phone: "",
			vid: "",
			position: "",
			location: "",
		},

		credentials: {
			authenticated: false,
			username: "",
			token: "",
			refresh: "",
		}
	},
	mutations: {
		SET_SHOWLOGIN: (state, value) => {
			state.showLogin = value
		},
		SET_CREDENTIALS: (state, credentials) => {
			state.credentials = credentials
		},
		SET_USERINFO: (state, userInfo) => {
			state.userInfo = userInfo
		},
		SET_AUTHENTICATED: (state, value) => {
			state.credentials.authenticated = value
		},
	},
	actions: {
		SET_SHOWLOGIN(context, value) {
			context.commit('SET_SHOWLOGIN', value)
		},
		SET_CREDENTIALS(context, credentials) {
			context.commit('SET_CREDENTIALS', credentials)
		},
		SET_USERINFO(context, userInfo) {
			context.commit('SET_USERINFO', userInfo)
		},
		SET_AUTHENTICATED(context, value) {
			context.commit('SET_AUTHENTICATED', value)
		},
	},
	getters: {
		GET_SHOWLOGIN: state => {
			return state.showLogin
		},
		GET_CREDENTIALS: state => {
			return state.credentials
		},
		GET_USERINFO: state => {
			return state.userInfo
		},
		GET_DISCOUNT: state => {
			return state.userInfo.discount
		},
		GET_AUTHENTICATED: state => {
			return state.credentials.authenticated
		},
		GET_USERNAME: state => {
			return state.credentials.username
		},
		GET_TOKEN: state => {
			return state.credentials.token
		},
		GET_REFRESH: state => {
			return state.credentials.refresh
		},
		GET_VID: state => {
			return state.userInfo.vid
		},
		GET_NAME: state => {
			return state.userInfo.name
		},
	}
}
