<template>
	<div class="login">
		<v-spacer />
		
		<div class="login-window">
			<h3>Sign In:</h3>

			<v-form style="width: 100%;">
				<!-- Email Input -->
					<v-text-field
						style="margin-bottom: 5px;"
						v-model="username"
						:rules="[rules.required, rules.email]"
						label="Username"
						placeholder=" "
						hide-details="auto"
						outlined
						dense />
					
				<!-- Password Input -->
					<v-text-field
						type="password"
						v-model="password"
						:rules="[rules.required, rules.counter]"
						label="Password"
						hide-details="auto"
						outlined
						dense
						clearable 
						@keyup.enter.native="attemptSignIn()"/>
			</v-form>

			<!-- Invalid Credentials Display -->
			<span style="color: red;"
				v-if="authError.length > 0">
				{{ this.authError }}
			</span>

			<!-- Bottom Button Display -->
			<div class="login-buttons">
				<v-spacer />

				<v-btn
					style="margin-right: 5px;"
					color="primary"
					:disabled="!canSignIn"
					:loading="loading"
					@click="attemptSignIn()">
					Sign In
				</v-btn>

				<v-btn
					color="default"
					:disabled="loading"
					@click="hideLogin()">
					Cancel
				</v-btn>

				<v-spacer />
			</div>

			<!-- Remember Me Display -->
			<div class="remember">
				<v-checkbox
					label="Remember Me"
					v-model="remember"
					hide-details="auto"
					dense>
				</v-checkbox>

				<span style="font-size: smaller;">
					Remember your sign in for 30 days.
				</span>
			</div>
		</div>

		<v-spacer />
	</div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

export default {
	name: 'Login',
	components: {
	},
	props: {
	},
	data () {
		return {
			username: "",
			password: "",
			remember: false,

			loading: false,

			rules: {
				required: value => !!value || 'Required.',
				counter: value => value.length >= 8 || 'Minimum 8 characters',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
			},
		  	authError: "",
			credentials: {
				authenticated: false,
				username: "",
				token: "",
				refresh: "",
				loading: false,
			},
			userInfo: {
				name: "",
				email: "",
				phone: "",
				vid: "",
				position: "",
				location: ""
			},
		}
	},
	methods: {
		attemptSignIn() {
			if (!this.canSignIn || this.loading){
				return
			}
			
			// Check Database for valid user.
			this.loading = true;
			this.authError = ""
			this.username = this.username.trim()

			var formData = new FormData();
			formData.append("email", this.username)

			axios.post(`${this.$config.api}/users.php?cmd=validateUser`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// Account doesn't exist
					if (!response.data.success){
						this.loading = false;
						this.authError = `* This account does not exist.`
						
						console.log(`Auth Error: ${this.authError}`)
						console.log(response.data)
						return
					}

					// Account is disabled
					if (!response.data.active){
						this.loading = false;
						this.authError = `* This account has been disabled.`
						console.log(`Auth Error: ${this.authError}`)
						return
					}		

					// Account is active
					this.$cookie.set("username", this.username, 30)
					
					// Check account with Firebase.
					const auth = getAuth()
					signInWithEmailAndPassword(auth, this.username, this.password)
						.then( response => {
							this.credentials = {
								authenticated: true,
								username: response.user.email,
								token: response.user.accessToken,
								refresh: response.user.stsTokenManager.refreshToken
							}
			
							// Successful login so set user credentials in database
							this.submitUserCredentials()
						})
						.catch(error => {
							this.authError = `* Username or Password invalid.`
							console.log(`Auth Error: ${this.authError}`)
							this.loading = false
						})
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
					
					this.authError = "* Unknown Error. Email parts@supremeinternational.com for help."
					console.log(`Auth Error: ${this.authError}`)
					this.loading = false
				})
		},
		hideLogin() {
			this.$store.dispatch("authentication/SET_SHOWLOGIN", false)
		},
		submitUserCredentials() {
			this.loading = true;

			var formData = new FormData();
			formData.append("email", this.credentials.username)
			formData.append("auth", this.credentials.token)
			formData.append("refresh", this.credentials.refresh)

			axios.post(`${this.$config.api}/users.php?cmd=submitUserCredentials`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED
					if (!response.data.success){
						console.log("Submit User Credentials Failed.")
						console.log(response.data)
						this.loading = false;
						return
					}

					// API Success
					this.getUserInfo()
					this.loading = false
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		getUserInfo() {
			this.userInfo = {
				name: "",
				email: "",
				phone: "",
				vid: "",
				position: "",
				location: ""
			}
			
			this.loading = true;

			var formData = new FormData();
			// Credentials
			formData.append("username", this.credentials.username)
			formData.append("token", this.credentials.token)

			// API Data
			formData.append("email", this.username)

			axios.post(`${this.$config.api}/users.php?cmd=getUserInfo`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					// API FAILED
					if (!response.data.success){
						console.log("Get User Information Failed.")
						console.log(response.data)
						this.authError = "*Login Failed - Please Try Again"
						this.loading = false;
						return
					}

					// API Success
					this.userInfo = response.data.user

					// Set authentication store information
					this.$store.dispatch("authentication/SET_CREDENTIALS", this.credentials)
					this.$store.dispatch("authentication/SET_SHOWLOGIN", false)
					this.$store.dispatch("authentication/SET_USERINFO", this.userInfo)
					this.$store.dispatch("cart/SET_DISCOUNT", this.userInfo.discount)

					// Write the login and user information to a cookie if enabled
					if (this.remember) {
						this.writeLoginCookie(true)
					} else {
						this.writeLoginCookie(false)
					}

					this.loading = false
				})
				.catch(error => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log("Server returned status code:")
						console.log(error.response.data)
						console.log(error.response.status)
						console.log(error.response.headers)
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log("Server didn't respond:")
						console.log(error.request)
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Unknown Error:")
						console.log('Error', error.message)
					}
				})
		},
		writeLoginCookie(remember) {
			if (remember){
				this.$cookie.set("credentials", JSON.stringify(this.credentials), 30)
				this.$cookie.set("userinfo", JSON.stringify(this.userInfo), 30)
			} else {
				var credentials = {
					authenticated: false,
					first: "",
					last: "",
					username: "",
					token: "",
				}			

				var userinfo = {
					id: "",
					name: "",
					email: "",
					phone: "",
					vid: "",
					position: "",
					location: "",
					active: "",
					activated: ""
				}

				this.$cookie.set("credentials", JSON.stringify(credentials), 30)
				this.$cookie.set("userinfo", JSON.stringify(userinfo), 30)
			}
		},
	},
	computed: {
		canSignIn() {
			return this.username.length >= 8 && this.password.length >= 8 && !this.loading
		}
	},
	mounted() {
		if (this.$cookie.get("username")){
			this.username = this.$cookie.get("username")
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.login {
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 100;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0px;
		top: 0px;
		background: rgba(0,0,0,0.25);
		text-align: center;
		border: 1px solid red;
	}

	.login-window {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: azure;
		width: 350px;
		padding: 5px;
		border: 2px solid silver;
		border-radius: 5px;

	}

	.login-buttons {
		display: flex;
		flex-direction: row;
		margin-top: 10px;
		text-align: center;
	}

	.inputs {
		width: 100%;
	}

	.remember {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 10px;
		width: 100%;
		border-top: 1px solid silver;
	}
</style>