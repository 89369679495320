<template>
	<div class="orders">
		<!-- Loading Component -->
		<div class="loading"
			v-if="loading">
			<v-spacer/>

			<v-progress-circular
				:size="100"
				color="primary"
				indeterminate/>

			<v-spacer/>
		</div>

		<!-- Main Orders Container Component -->
		<div class="main-container">
			<div class="search-container">
				<!-- Filter By PO Field -->
				<div class="field">
					<v-text-field
						type="text"
						v-model="po"
						label="Filter By PO"
						hide-details="auto"
						outlined
						clearable
						dense/>
				</div>

				<!-- Filter By Order ID Field -->
				<div class="field">
					<v-text-field
						type="text"
						v-model="orderID"
						label="Filter By Order Number"
						hide-details="auto"
						outlined
						clearable
						dense/>
				</div>
			</div>

			<div class="orders-container">
				<!-- Left Panel Order List -->
				<div class="orders-list">
					<!-- No Orders Found Component -->
					<div class="no-orders"
						v-if="orders.length == 0">
						No Orders Found
					</div>

					<!-- Order Status Expansion Panels -->
					<v-expansion-panels
						v-if="orders.length > 0"
						accordion
						tile>
						<!-- Order Placed Panel -->
						<v-expansion-panel
							v-if="placedOrders.length > 0">
							<v-expansion-panel-header ripple>
								<div class="order-badge">
									<v-badge
										v-if="placedOrders.length > 0"
										bordered
										medium
										inline
										color="primary" 
										:content="placedOrders.length"
										offset-x="15"
										offset-y="-9"/>

								Placed Orders
								</div>
							</v-expansion-panel-header>
							
							<v-expansion-panel-content
								v-for="order, index in placedOrders"
								:key="index">
								<div class="order-summary"
									v-show="showResult(order)"
									@click="getOrder(order)">
									<!-- Top Order Number/Date Row -->									
									<div class="row">
										<div class="title"
											style="width: 35%;">
											Order #{{order.orderID}}
										</div>

										<div style="font-size: 1.5vh; margin-left: 10px; width: 65%; text-align: right;">
											<b>Date Ordered:</b> {{ order.createDate }}
										</div>
									</div>

									<!-- PO Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>PO:</b> {{ order.po }}
										</div>
									</div>

									<!-- Courier Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Courier:</b> {{ order.courierName }}
										</div>

										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Account:</b> {{ order.courierAccount }}
										</div>
									</div>

									<!-- Urgency Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Urgency:</b> {{ order.urgency }}
										</div>
									</div>

									<!-- Order Note Row -->									
									<div v-if="order.orderNote.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Order Note:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.orderNote"></span>
											</div>
										</div>
									</div>

									<!-- Comment Row -->									
									<div v-if="order.comment.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Comment:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.comment"></span>
											</div>
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- Order Processed Panel -->
						<v-expansion-panel
							v-if="processedOrders.length > 0">
							<v-expansion-panel-header>
								<div class="order-badge">
									<v-badge
										v-if="processedOrders.length > 0"
										bordered
										medium
										inline
										color="primary" 
										:content="processedOrders.length"
										offset-x="15"
										offset-y="-9"/>

								Processed Orders
								</div>
							</v-expansion-panel-header>

							<v-expansion-panel-content
								v-for="order, index in processedOrders"
								:key="index">
								<div class="order-summary"
									v-show="showResult(order)"
									@click="getOrder(order)">
									<!-- Top Order Number/Date Row -->									
									<div class="row">
										<div class="title"
											style="width: 35%;">
											Order #{{order.orderID}}
										</div>

										<div style="font-size: 1.5vh; margin-left: 10px; width: 65%; text-align: right;">
											<b>Date Ordered:</b> {{ order.createDate }}
										</div>
									</div>

									<!-- PO Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>PO:</b> {{ order.po }}
										</div>
									</div>

									<!-- Courier Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Courier:</b> {{ order.courierName }}
										</div>

										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Account:</b> {{ order.courierAccount }}
										</div>
									</div>

									<!-- Urgency Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Urgency:</b> {{ order.urgency }}
										</div>
									</div>

									<!-- Order Note Row -->									
									<div v-if="order.orderNote.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Order Note:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.orderNote"></span>
											</div>
										</div>
									</div>

									<!-- Comment Row -->									
									<div v-if="order.comment.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Comment:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.comment"></span>
											</div>
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- Order Shipped Panel -->
						<v-expansion-panel
							v-if="shippedOrders.length > 0">
							<v-expansion-panel-header>
								<div class="order-badge">
									<v-badge
										v-if="shippedOrders.length > 0"
										bordered
										medium
										inline
										color="primary" 
										:content="shippedOrders.length"
										offset-x="15"
										offset-y="-9"/>

								Shipped Orders
								</div>
							</v-expansion-panel-header>

							<v-expansion-panel-content
								v-for="order, index in shippedOrders"
								:key="index">
								<div class="order-summary"
									v-show="showResult(order)"
									@click="getOrder(order)">
									<!-- Top Order Number/Date Row -->									
									<div class="row">
										<div class="title"
											style="width: 35%;">
											Order #{{order.orderID}}
										</div>

										<div style="font-size: 1.5vh; margin-left: 10px; width: 65%; text-align: right;">
											<b>Date Ordered:</b> {{ order.createDate }}
										</div>
									</div>

									<!-- PO Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>PO:</b> {{ order.po }}
										</div>
									</div>

									<!-- Courier Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Courier:</b> {{ order.courierName }}
										</div>

										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Account:</b> {{ order.courierAccount }}
										</div>
									</div>

									<!-- Urgency Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Urgency:</b> {{ order.urgency }}
										</div>
									</div>

									<!-- Order Note Row -->									
									<div v-if="order.orderNote.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Order Note:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.orderNote"></span>
											</div>
										</div>
									</div>

									<!-- Comment Row -->									
									<div v-if="order.comment.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Comment:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.comment"></span>
											</div>
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- Order Cancelled Panel -->
						<v-expansion-panel
							v-if="cancelledOrders.length > 0">
							<v-expansion-panel-header>
								<div class="order-badge">
									<v-badge
										v-if="cancelledOrders.length > 0"
										bordered
										medium
										inline
										color="primary" 
										:content="cancelledOrders.length"
										offset-x="15"
										offset-y="-9"/>

								Cancelled Orders
								</div>
							</v-expansion-panel-header>

							<v-expansion-panel-content
								v-for="order, index in cancelledOrders"
								:key="index">
								<div class="order-summary"
									v-show="showResult(order)"
									@click="getOrder(order)">
									<!-- Top Order Number/Date Row -->									
									<div class="row">
										<div class="title"
											style="width: 35%;">
											Order #{{order.orderID}}
										</div>

										<div style="font-size: 1.5vh; margin-left: 10px; width: 65%; text-align: right;">
											<b>Date Ordered:</b> {{ order.createDate }}
										</div>
									</div>

									<!-- PO Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>PO:</b> {{ order.po }}
										</div>
									</div>

									<!-- Courier Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Courier:</b> {{ order.courierName }}
										</div>

										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Account:</b> {{ order.courierAccount }}
										</div>
									</div>

									<!-- Urgency Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Urgency:</b> {{ order.urgency }}
										</div>
									</div>

									<!-- Order Note Row -->									
									<div v-if="order.orderNote.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Order Note:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.orderNote"></span>
											</div>
										</div>
									</div>

									<!-- Comment Row -->									
									<div v-if="order.comment.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Comment:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.comment"></span>
											</div>
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>

						<!-- Order Archived Panel -->
						<v-expansion-panel
							v-if="archivedOrders.length > 0">
							<v-expansion-panel-header>
								<div class="order-badge">
									<v-badge
										v-if="archivedOrders.length > 0"
										bordered
										medium
										inline
										color="primary" 
										:content="archivedOrders.length"
										offset-x="15"
										offset-y="-9"/>

								Archived Orders
								</div>
							</v-expansion-panel-header>

							<v-expansion-panel-content
								v-for="order, index in archivedOrders"
								:key="index">
								<div class="order-summary"
									v-show="showResult(order)"
									@click="getOrder(order)">
									<!-- Top Order Number/Date Row -->									
									<div class="row">
										<div class="title"
											style="width: 35%;">
											Order #{{order.orderID}}
										</div>

										<div style="font-size: 1.5vh; margin-left: 10px; width: 65%; text-align: right;">
											<b>Date Ordered:</b> {{ order.createDate }}
										</div>
									</div>

									<!-- PO Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>PO:</b> {{ order.po }}
										</div>
									</div>

									<!-- Courier Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Courier:</b> {{ order.courierName }}
										</div>

										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Account:</b> {{ order.courierAccount }}
										</div>
									</div>

									<!-- Urgency Row -->									
									<div class="row">
										<div style="font-size: 1.5vh; margin-left: 15px;">
											<b>Urgency:</b> {{ order.urgency }}
										</div>
									</div>

									<!-- Order Note Row -->									
									<div v-if="order.orderNote.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Order Note:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.orderNote"></span>
											</div>
										</div>
									</div>

									<!-- Comment Row -->									
									<div v-if="order.comment.length > 0">
										<div class="row">
											<div style="font-size: 1.5vh; margin-left: 15px;">
												<b>Comment:</b>
											</div>
										</div>

										<div class="row">
											<div class="note">
												<span style="white-space: pre;" v-html="order.comment"></span>
											</div>
										</div>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>

				<!-- Right Panel Order View -->
				<div class="order-view">
					<view-order
						v-if="order != null && order.id != null"
						:order="order"
						:parts="parts"
						:logs="logs"
						:dealer="dealer"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { EventBus } from '../../javascript/event-bus'			// Imports Event Bus.

import ViewOrder from './view_order'							// Imports shopping cart component.

export default {
	name: 'Orders',
	components: {
		ViewOrder,
	},
	props: {
	},
	data () {
		return {
			loading: false,
			po: "",
			orderID: "",

			dealer: {},

			// Order Arrays
			orders: [],
			placedOrders: [],
			processedOrders: [],
			shippedOrders: [],
			cancelledOrders: [],
			archivedOrders: [],

			// View Order Variables
			order: {},
			parts: [],
			logs: [],
		}
	},
	methods: {
		getOrders() {
			this.loading = true
			var formData = new FormData();

			this.clear()

			// Credentials
			formData.append("username", this.$store.getters["authentication/GET_CREDENTIALS"].username)
			formData.append("token", this.$store.getters["authentication/GET_CREDENTIALS"].token)

			// API Data
			formData.append("vid", this.$store.getters["authentication/GET_VID"])

			axios.post(`${this.$config.api}/order.php?cmd=getAllOrdersByID&username=${this.$store.getters["authentication/GET_USERNAME"]}&token=${this.$store.getters["authentication/GET_TOKEN"]}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					if (response.data.action == "login"){
						this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
						this.$store.dispatch('authentication/SET_CREDENTIALS', {})

						EventBus.$emit('invalidLogin')
						return
					}

					this.loading = false;
					return
				}

				// API Success
				this.loading = false
				this.orders = response.data.orders
				this.dealer = response.data.dealer

				this.sortOrders()
			})
			.catch(e => {
				console.log(`orders: getAllOrdersByVID - Get Salt API Error (${e})`)
				this.loading = false;
			})
		},
		getOrder(order) {
			console.log(`Get order: ${order.orderID}`)
			this.loading = true
			var formData = new FormData();

			this.order = {}

			// Credentials
			formData.append("username", this.$store.getters["authentication/GET_CREDENTIALS"].username)
			formData.append("token", this.$store.getters["authentication/GET_CREDENTIALS"].token)

			// API Data
			formData.append("orderid", order.orderID)

			axios.post(`${this.$config.api}/order.php?cmd=getOrderByOrderID&username=${this.$store.getters["authentication/GET_USERNAME"]}&token=${this.$store.getters["authentication/GET_TOKEN"]}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				// API FAILED
				if (!response.data.success){
					this.loading = false;

					if (response.data.action == "login"){
						this.$store.dispatch('authentication/SET_AUTHENTICATED', false)
						this.$store.dispatch('authentication/SET_CREDENTIALS', {})

						EventBus.$emit('invalidLogin')
						return
					}

					console.log("Get Order Error:")
					console.log(response.data)
					return
				}

				// API Success
				this.loading = false
				this.order = response.data.order
				this.parts = response.data.parts
				this.logs = response.data.logs
				this.dealer = response.data.dealer
				console.log(response.data)
			})
			.catch(e => {
				console.log(`orders: getOrderByOrderID - Get Salt API Error (${e})`)
				this.loading = false;
			})
		},
		sortOrders() {
			if (this.orders.length == 0) {
				return
			}

			for (var order of this.orders) {
				switch (order.status) {
					case "Order Placed":
						this.placedOrders.push(order)
						break
					case "Order Processed":
						this.processedOrders.push(order)
						break
					case "Order Shipped":
						this.shippedOrders.push(order)
						break
					case "Order Cancelled":
						this.cancelledOrders.push(order)
						break
					case "Order Archived":
						this.archivedOrders.push(order)
						break
					default:
						console.log(`Unknown order status: Order #${order.orderID} - ${order.status}`)
						break
				}
			}
		},
		clear() {
			this.orders = []
			this.placedOrders = []
			this.processedOrders = []
			this.shippedOrders = []
			this.cancelledOrders = []
			this.archivedOrders = []
		},
		showResult(order) {
			if (!this.hasFilter) {
				return true
			}
			if (this.po != null && this.po != "" && order.po.toLowerCase().includes(this.po.toLowerCase())) {
				return true
			 } 
			 
			 if (this.orderID != null && this.orderID != "" && order.orderID.toString().includes(this.orderID)) {
				return true
			 }

			 return false
		},
	},
	computed: {
		hasFilter() {
			if ((this.po == null || this.po == "") && 
				(this.orderID == null || this.orderID == "")) {
				return false
			}

			return true
		},
	},
	mounted() {
		// Event Bus Actions
		EventBus.$on('orderRefresh', this.getOrders)
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.orders {
		width: 100%;
		height: 100%;
		overflow: hidden;
		font-size: 2vh;
	}

	.main-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}

	.search-container {
		display: flex;
		flex-direction: row;
		flex-grow: 0;
		min-height: 50px;
		border: 1px solid silver;
		background-color: white;
		padding: 5px;
		overflow: hidden;
	}

	.orders-container {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		border: 1px solid silver;
		background-color: white;
		margin-top: 5px;
		overflow: hidden;
		padding: 5px;
	}

	.orders-list {
		display: flex;
		flex-direction: column;
		flex-grow: 0;
		align-items: center;
		height: 100%;
		width: 40vw;
		border: 1px solid silver;
		background-color: white;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
		margin-right: 5px;
	}

	.order-view {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		width: 100%;
		border: 1px solid silver;
		background-color: white;
		padding: 5px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.field {
		height: 100%;
		width: 20vw;
		margin-right: 5px;
	}

	.no-orders {
		width: 95%;
		padding: 10px;
		border: 1px solid silver;
		background-color: blanchedalmond;
		color: black;
		text-align: center;
		margin-top: 35vh;
	}

	.order-summary {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: 80px;
		border: 1px solid silver;
		border-radius: 5px;
		padding: 10px;
		background-color: azure;
	}

	.row {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}

	.note {
		font-size: 1.5vh; 
		margin-left: 15px; 
		width: 95%; 
		min-height: 40px;
		max-height: 120px;
		border: 1px solid gray; 
		border-radius: 5px;
		background-color: beige;
		padding: 2px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.loading {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,.25);
		height: 100%;
		width: 100%;
		text-align: center;
		z-index: 5;
	}

</style>